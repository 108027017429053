<app-header1></app-header1>
<!--====== Breadcrumb part Start ======-->
<div
  class="breadcrumb-section bg-img-c"
  style="background-image: url(assets/img/breadcrumb.jpg)"
>
  <div class="container">
    <div class="breadcrumb-text">
      <h1 class="page-title">Rejoindre Saleem</h1>
    </div>
  </div>
</div>
<!--====== Breadcrumb part End ======-->

<!--====== Blog Section Start ======-->
<section class="blog-section" style="margin-bottom: 20px">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <!-- Blog Details -->

        <!-- Post Author -->

        <!-- Post Nav -->

        <!-- Related Post -->

        <!-- Comments Template -->
        <div class="comment-template">
          <!-- Comments List -->

          <!-- Comments form -->
          <div class="comment-form">
            <h3 class="title">Inscription</h3>
            <form [formGroup]="form" (ngSubmit)="submit()">
              <div class="row">
                <div class="col-lg-6">
                  <div class="input-group mb-30">
                    <p class="col-lg-12 placeholder">Nom</p>
                    <input
                      type="hidden"
                      value="CANDIDAT"
                      name="role"
                      formControlName="role"
                    />
                    <input
                      type="text"
                      placeholder="Votre nom"
                      formControlName="lastname"
                    />
                    <span class="icon"><i class="far fa-user"></i></span>
                    <div
                      *ngIf="
                        form.get('lastname').errors &&
                        form.get('lastname').touched
                      "
                      class="error"
                    >
                      <p *ngIf="form.get('lastname').errors.required">
                        Le nom du candidat est obligatoire !
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="input-group mb-30">
                    <p class="col-lg-12 placeholder">Prénom</p>
                    <input
                      type="text"
                      placeholder="Votre prénom"
                      formControlName="firstname"
                    />
                    <span class="icon"><i class="far fa-user"></i></span>
                    <div
                      *ngIf="
                        form.get('firstname').errors &&
                        form.get('firstname').touched
                      "
                      class="error"
                    >
                      <p *ngIf="form.get('firstname').errors.required">
                        Le prénom du candidat est obligatoire !
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="input-group mb-30">
                    <p class="col-lg-12 placeholder">Email</p>
                    <input
                      type="email"
                      value="{{ emailx }}"
                      [value]="emailx"
                      placeholder="Votre email "
                      formControlName="mail"
                    />

                    <span class="icon"><i class="far fa-envelope"></i></span>
                    <div
                      *ngIf="
                        form.get('mail').errors && form.get('mail').touched
                      "
                      class="error"
                    >
                      <p *ngIf="form.get('mail').errors.required">
                        L'email du candidat est obligatoire !
                      </p>
                      <p *ngIf="form.get('mail').errors.email">
                        Le format de l'email est incorrecte
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="input-group mb-30">
                    <p class="col-lg-12 placeholder">Date de naissance</p>

                    <input
                      type="date"
                      placeholder="Votre date de naissance "
                      formControlName="birthdate"
                    />

                    <span class="icon"
                      ><i class="far fa-calendar-alt"></i
                    ></span>
                    <div
                      *ngIf="
                        form.get('birthdate').errors &&
                        form.get('birthdate').touched
                      "
                      class="error"
                    >
                      <p *ngIf="form.get('birthdate').errors.required">
                        La date de naissance du candidat est obligatoire !
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="input-group mb-30">
                    <p class="col-lg-12 placeholder">Mot de passe</p>
                    <input
                      type="password"
                      placeholder="Votre mot de passe "
                      formControlName="password"
                    />

                    <span class="icon"><i class="far fa-shield"></i></span>
                    <div
                      *ngIf="
                        form.get('password').errors &&
                        form.get('password').touched
                      "
                      class="error"
                    >
                      <p *ngIf="form.get('password').errors.required">
                        Le mot de passe du candidat est obligatoire !
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="input-group mb-30">
                    <p class="col-lg-12 placeholder">Téléphone</p>
                    <input
                      type="tel"
                      placeholder="Votre Téléphone "
                      formControlName="telephone"
                    />

                    <span class="icon"><i class="far fa-phone"></i></span>
                    <div
                      *ngIf="
                        form.get('telephone').errors &&
                        form.get('telephone').touched
                      "
                      class="error"
                    >
                      <p *ngIf="form.get('telephone').errors.required">
                        Le téléphone du candidat est obligatoire !
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="input-group mb-30">
                    <p class="col-lg-12 placeholder">Lieu de naissance</p>

                    <input
                      type="text"
                      placeholder="Votre lieu de naissance "
                      formControlName="lieuNaissance"
                    />

                    <span class="icon"><i class="far fa-location"></i></span>
                    <div
                      *ngIf="
                        form.get('lieuNaissance').errors &&
                        form.get('lieuNaissance').touched
                      "
                      class="error"
                    >
                      <p *ngIf="form.get('lieuNaissance').errors.required">
                        Le lieu de naissance du candidat est obligatoire !
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="input-group mb-30">
                    <p class="col-lg-12 placeholder">Pays de nationalité</p>

                    <select formControlName="paysNationalite">
                      <option value="" disabled selected>Choisir</option>
                      <option>Afghanistan</option>
                      <option>Îles Åland</option>
                      <option>Albanie</option>
                      <option>Algérie</option>
                      <option>Samoa américaines</option>
                      <option>Andorre</option>
                      <option>Angola</option>
                      <option>Anguilla</option>
                      <option>Antarctique</option>
                      <option>Antigua-et-Barbuda</option>
                      <option>Argentine</option>
                      <option>Arménie</option>
                      <option>Aruba</option>
                      <option>Australie</option>
                      <option>Autriche</option>
                      <option>Azerbaïdjan</option>
                      <option>Bahamas</option>
                      <option>Bahreïn</option>
                      <option>Bangladesh</option>
                      <option>Barbade</option>
                      <option>Biélorussie</option>
                      <option>Belgique</option>
                      <option>Belize</option>
                      <option>Bénin</option>
                      <option>Bermudes</option>
                      <option>Bhoutan</option>
                      <option>Bolivie</option>
                      <option>Bosnie-Herzégovine</option>
                      <option>Botswana</option>
                      <option>Île Bouvet</option>
                      <option>Brésil</option>
                      <option>British Virgin Islands</option>
                      <option>Territoire britannique de l’Océan Indien</option>
                      <option>Brunei Darussalam</option>
                      <option>Bulgarie</option>
                      <option>Burkina Faso</option>
                      <option>Burundi</option>
                      <option>Cambodge</option>
                      <option>Cameroun</option>
                      <option>Canada</option>
                      <option>Cap-Vert</option>
                      <option>Iles Cayman</option>
                      <option>République centrafricaine</option>
                      <option>Tchad</option>
                      <option>Chili</option>
                      <option>Chine</option>
                      <option>Hong Kong</option>
                      <option>Macao</option>
                      <option>Île Christmas</option>
                      <option>Îles Cocos</option>
                      <option>Colombie</option>
                      <option>Comores</option>
                      <option>République du Congo</option>
                      <option>République démocratique du Congo</option>
                      <option>Îles Cook</option>
                      <option>Costa Rica</option>
                      <option>Côte d’Ivoire</option>
                      <option>Croatie</option>
                      <option>Cuba</option>
                      <option>Chypre</option>
                      <option>République tchèque</option>
                      <option>Danemark</option>
                      <option>Djibouti</option>
                      <option>Dominique</option>
                      <option>République dominicaine</option>
                      <option>Équateur</option>
                      <option>Égypte</option>
                      <option>Salvador</option>
                      <option>Guinée équatoriale</option>
                      <option>Érythrée</option>
                      <option>Estonie</option>
                      <option>Éthiopie</option>
                      <option>Îles Falkland</option>
                      <option>Îles Féroé</option>
                      <option>Fidji</option>
                      <option>Finlande</option>
                      <option>France</option>
                      <option>Guyane française</option>
                      <option>Polynésie française</option>
                      <option>
                        Terres australes et antarctiques françaises
                      </option>
                      <option>Gabon</option>
                      <option>Gambie</option>
                      <option>Géorgie</option>
                      <option>Allemagne</option>
                      <option>Ghana</option>
                      <option>Gibraltar</option>
                      <option>Grèce</option>
                      <option>Groenland</option>
                      <option>Grenade</option>
                      <option>Guadeloupe</option>
                      <option>Guam</option>
                      <option>Guatemala</option>
                      <option>Guernesey</option>
                      <option>Guinée</option>
                      <option>Guinée-Bissau</option>
                      <option>Guyane</option>
                      <option>Haïti</option>
                      <option>Îles Heard-et-MacDonald</option>
                      <option>Saint-Siège (Vatican)</option>
                      <option>Honduras</option>
                      <option>Hongrie</option>
                      <option>Islande</option>
                      <option>Inde</option>
                      <option>Indonésie</option>
                      <option>Iran</option>
                      <option>Irak</option>
                      <option>Irlande</option>
                      <option>Ile de Man</option>
                      <option>Israël</option>
                      <option>Italie</option>
                      <option>Jamaïque</option>
                      <option>Japon</option>
                      <option>Jersey</option>
                      <option>Jordanie</option>
                      <option>Kazakhstan</option>
                      <option>Kenya</option>
                      <option>Kiribati</option>
                      <option>Corée du Nord</option>
                      <option>Corée du Sud</option>
                      <option>Koweït</option>
                      <option>Kirghizistan</option>
                      <option>Laos</option>
                      <option>Lettonie</option>
                      <option>Liban</option>
                      <option>Lesotho</option>
                      <option>Libéria</option>
                      <option>Libye</option>
                      <option>Liechtenstein</option>
                      <option>Lituanie</option>
                      <option>Luxembourg</option>
                      <option>Macédoine</option>
                      <option>Madagascar</option>
                      <option>Malawi</option>
                      <option>Malaisie</option>
                      <option>Maldives</option>
                      <option>Mali</option>
                      <option>Malte</option>
                      <option>Îles Marshall</option>
                      <option>Martinique</option>
                      <option>Mauritanie</option>
                      <option>Maurice</option>
                      <option>Mayotte</option>
                      <option>Mexique</option>
                      <option>Micronésie</option>
                      <option>Moldavie</option>
                      <option>Monaco</option>
                      <option>Mongolie</option>
                      <option>Monténégro</option>
                      <option>Montserrat</option>
                      <option>Maroc</option>

                      <option>Mozambique</option>
                      <option>Myanmar</option>
                      <option>Namibie</option>
                      <option>Nauru</option>
                      <option>Népal</option>
                      <option>Pays-Bas</option>
                      <option>Nouvelle-Calédonie</option>
                      <option>Nouvelle-Zélande</option>
                      <option>Nicaragua</option>
                      <option>Niger</option>
                      <option>Nigeria</option>
                      <option>Niue</option>
                      <option>Île Norfolk</option>
                      <option>Îles Mariannes du Nord</option>
                      <option>Norvège</option>
                      <option>Oman</option>
                      <option>Pakistan</option>
                      <option>Palau</option>
                      <option>Palestine</option>
                      <option>Panama</option>
                      <option>Papouasie-Nouvelle-Guinée</option>
                      <option>Paraguay</option>
                      <option>Pérou</option>
                      <option>Philippines</option>
                      <option>Pitcairn</option>
                      <option>Pologne</option>
                      <option>Portugal</option>
                      <option>Puerto Rico</option>
                      <option>Qatar</option>
                      <option>Réunion</option>
                      <option>Roumanie</option>
                      <option>Russie</option>
                      <option>Rwanda</option>
                      <option>Saint-Barthélemy</option>
                      <option>Sainte-Hélène</option>
                      <option>Saint-Kitts-et-Nevis</option>
                      <option>Sainte-Lucie</option>
                      <option>Saint-Martin (partie française)</option>
                      <option>Saint-Martin (partie néerlandaise)</option>
                      <option>Saint-Pierre-et-Miquelon</option>
                      <option>Saint-Vincent-et-les Grenadines</option>
                      <option>Samoa</option>
                      <option>Saint-Marin</option>
                      <option>Sao Tomé-et-Principe</option>
                      <option>Arabie Saoudite</option>
                      <option>Sénégal</option>
                      <option>Serbie</option>
                      <option>Seychelles</option>
                      <option>Sierra Leone</option>
                      <option>Singapour</option>
                      <option>Slovaquie</option>
                      <option>Slovénie</option>
                      <option>Îles Salomon</option>
                      <option>Somalie</option>
                      <option>Afrique du Sud</option>
                      <option>
                        Géorgie du Sud et les îles Sandwich du Sud
                      </option>
                      <option>Sud-Soudan</option>
                      <option>Espagne</option>
                      <option>Sri Lanka</option>
                      <option>Soudan</option>
                      <option>Suriname</option>
                      <option>Svalbard et Jan Mayen</option>
                      <option>Eswatini</option>
                      <option>Suède</option>
                      <option>Suisse</option>
                      <option>Syrie</option>
                      <option>Taiwan</option>
                      <option>Tadjikistan</option>
                      <option>Tanzanie</option>
                      <option>Thaïlande</option>
                      <option>Timor-Leste</option>
                      <option>Togo</option>
                      <option>Tokelau</option>
                      <option>Tonga</option>
                      <option>Trinité-et-Tobago</option>
                      <option>Tunisie</option>
                      <option>Turquie</option>
                      <option>Turkménistan</option>
                      <option>Îles Turques-et-Caïques</option>
                      <option>Tuvalu</option>
                      <option>Ouganda</option>
                      <option>Ukraine</option>
                      <option>Émirats Arabes Unis</option>
                      <option>Royaume-Uni</option>
                      <option>États-Unis</option>
                      <option>Îles mineures éloignées des États-Unis</option>
                      <option>Uruguay</option>
                      <option>Ouzbékistan</option>
                      <option>Vanuatu</option>
                      <option>Venezuela</option>
                      <option>Viêt Nam</option>
                      <option>Îles Vierges américaines</option>
                      <option>Wallis-et-Futuna</option>
                      <option>Sahara occidental</option>
                      <option>Yémen</option>
                      <option>Zambie</option>
                      <option>Zimbabwe</option>
                    </select>

                    <span class="icon"><i class="far fa-flag"></i></span>
                    <div
                      *ngIf="
                        form.get('paysNationalite').errors &&
                        form.get('paysNationalite').touched
                      "
                      class="error"
                    >
                      <p *ngIf="form.get('paysNationalite').errors.required">
                        Le pays de nationalité du candidat est obligatoire !
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="input-group mb-30">
                    <p class="col-lg-12 placeholder">Genre</p>

                    <select formControlName="genre" #genre>
                      <option value="" disabled selected>Choisir</option>
                      <option>Homme</option>
                      <option>Femme</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="input-group mb-30">
                    <p class="col-lg-12 placeholder">Carte d'identité</p>
                    <input
                      type="text"
                      placeholder="Carte d'identité nationale (CIN)"
                      formControlName="cin"
                    />

                    <span class="icon"><i class="far fa-id-card"></i></span>
                    <div
                      *ngIf="form.get('cin').errors && form.get('cin').touched"
                      class="error"
                    >
                      <p *ngIf="form.get('cin').errors.required">
                        La CIN du candidat est obligatoire !
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="input-group mb-30">
                    <p class="col-lg-12 placeholder">
                      Identifiant national étudiant
                    </p>
                    <input
                      type="text"
                      placeholder="Identifiant national étudiant (CNE)"
                      formControlName="cne"
                    />

                    <span class="icon"><i class="far fa-id-badge"></i></span>
                    <div
                      *ngIf="form.get('cne').errors && form.get('cne').touched"
                      class="error"
                    >
                      <p *ngIf="form.get('cne').errors.required">
                        La CNE du candidat est obligatoire !
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="input-group mb-30">
                    <p class="col-lg-12 placeholder">Handicap</p>

                    <select formControlName="handicap">
                      <option value="" disabled selected>Choisir</option>
                      <option value="false">Non</option>
                      <option value="true">Oui</option>
                    </select>

                    <span class="icon"><i class="far fa-wheelchair"></i></span>
                    <div
                      *ngIf="
                        form.get('handicap').errors &&
                        form.get('handicap').touched
                      "
                      class="error"
                    >
                      <p *ngIf="form.get('handicap').errors.required">
                        Ce champs est obligatoire !
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="form.get('handicap').value == 'true'"
                  class="col-lg-6"
                >
                  <div class="input-group mb-30">
                    <p class="col-lg-12 placeholder">Type de handicap</p>
                    <select formControlName="typeHandicap">
                      <option value="" selected>Choisir</option>

                      <option>Moteur</option>
                      <option>Visuel</option>
                      <option>Auditif</option>
                      <option>Autre</option>
                    </select>

                    <span class="icon"
                      ><i class="far fa-accessible-icon"></i
                    ></span>
                    <div
                      *ngIf="
                        form.get('typeHandicap').errors &&
                        form.get('typeHandicap').touched
                      "
                      class="error"
                    >
                      <p *ngIf="form.get('typeHandicap').errors.required">
                        Ce champs est obligatoire !
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="input-group mb-30">
                    <p class="col-lg-12 placeholder">
                      Statut de l'étudiant tout au long de l'année universitaire
                    </p>

                    <select formControlName="statutEtudiant">
                      <option value="" disabled selected>Choisir</option>

                      <option>Etudiant</option>
                      <option>Jeune diplomé</option>
                      <option>Autre</option>
                    </select>

                    <span class="icon"
                      ><i class="far fa-graduation-cap"></i
                    ></span>
                    <div
                      *ngIf="
                        form.get('statutEtudiant').errors &&
                        form.get('statutEtudiant').touched
                      "
                      class="error"
                    >
                      <p *ngIf="form.get('statutEtudiant').errors.required">
                        Ce champs est obligatoire !
                      </p>
                    </div>
                  </div>
                </div>
                <div class="separator col-lg-12 row"></div>
                <div class="col-lg-6">
                  <div class="input-group mb-30">
                    <p class="col-lg-12 placeholder">Type d'équipe</p>

                    <select formControlName="typeEquipe">
                      <option value="" disabled selected>Choisir</option>

                      <option>Individuel</option>
                      <option>Collectif</option>
                    </select>

                    <span class="icon"><i class="far fa-users"></i></span>
                    <div
                      *ngIf="
                        form.get('typeEquipe').errors &&
                        form.get('typeEquipe').touched
                      "
                      class="error"
                    >
                      <p *ngIf="form.get('typeEquipe').errors.required">
                        Le choix du format de l'équipe est obligatoire !
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="form.get('typeEquipe').value == 'Collectif'"
                  class="col-lg-12 row"
                >
                  <div
                    formArrayName="equipe"
                    *ngFor="
                      let item of form.get('equipe')['controls'];
                      let i = index
                    "
                  >
                    <div [formGroupName]="i" class="row col-lg-12">
                      <div class="col-lg-12">
                        <p>Collaborateur N{{ i + 1 }}</p>
                      </div>
                      <div class="col-lg-6">
                        <div class="input-group mb-30">
                          <p class="col-lg-12 placeholder">
                            Nom du collaborateur
                          </p>
                          <input
                            formControlName="nomCollaborateur"
                            placeholder="Nom du collaborateur"
                          />

                          <span class="icon"><i class="far fa-user"></i></span>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="input-group mb-30">
                          <p class="col-lg-12 placeholder">
                            Nom de l'établissement
                          </p>
                          <input
                            formControlName="nomEtablissement"
                            placeholder="Nom de l'établissement"
                          />

                          <span class="icon"
                            ><i class="far fa-university"></i
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="input-group mb-30">
                      <button
                        type="button"
                        class="btn btn-primary"
                        (click)="addItem()"
                      >
                        Ajouter un collaborateur
                      </button>
                    </div>
                  </div>
                </div>
                <div class="separator col-lg-12 row"></div>
                <div class="col-lg-6">
                  <div class="input-group mb-30">
                    <p class="col-lg-12 placeholder">Type de projet</p>

                    <select formControlName="typeProjet">
                      <option value="" disabled selected>Choisir</option>
                      <option>Création d'acitivté</option>
                      <option>Reprise d'activité</option>
                      <option>Transmission d'activité familiale</option>
                    </select>

                    <span class="icon"
                      ><i class="far fa-project-diagram"></i
                    ></span>
                    <div
                      *ngIf="
                        form.get('typeProjet').errors &&
                        form.get('typeProjet').touched
                      "
                      class="error"
                    >
                      <p *ngIf="form.get('typeProjet').errors.required">
                        Le type du projet est obligatoire !
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="input-group mb-30">
                    <p class="col-lg-12 placeholder">Nom du projet</p>
                    <input
                      type="text"
                      placeholder="Nom du projet"
                      formControlName="nomProjet"
                    />

                    <span class="icon"><i class="far fa-file-pen"></i></span>
                    <div
                      *ngIf="
                        form.get('nomProjet').errors &&
                        form.get('nomProjet').touched
                      "
                      class="error"
                    >
                      <p *ngIf="form.get('nomProjet').errors.required">
                        Le nom du projet est obligatoire !
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="input-group mb-30">
                    <p class="col-lg-12 placeholder">Résumé du projet</p>
                    <textarea
                      placeholder="Résumé du projet"
                      formControlName="resumeProjet"
                    ></textarea>

                    <span class="icon"
                      ><i class="far fa-file-signature"></i
                    ></span>
                    <div
                      *ngIf="
                        form.get('resumeProjet').errors &&
                        form.get('resumeProjet').touched
                      "
                      class="error"
                    >
                      <p *ngIf="form.get('resumeProjet').errors.required">
                        Le résumé du projet est obligatoire !
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="input-group mb-30">
                    <p class="col-lg-12 placeholder">
                      Valeur ajoutée du projet
                    </p>
                    <textarea
                      placeholder="Valeur ajoutée du projet"
                      formControlName="vaProjet"
                    ></textarea>

                    <span class="icon"><i class="far fa-trophy-alt"></i></span>
                    <div
                      *ngIf="
                        form.get('vaProjet').errors &&
                        form.get('vaProjet').touched
                      "
                      class="error"
                    >
                      <p *ngIf="form.get('vaProjet').errors.required">
                        La valeur ajoutée du projet est obligatoire !
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="input-group mb-30">
                    <p class="col-lg-12 placeholder">
                      Ou suis-je dans mon projet ?
                    </p>

                    <select formControlName="avancementProjet">
                      <option value="" disabled selected>Choisir</option>
                      <option>Non démarré</option>
                      <option>Démarré sans statut juridique</option>
                      <option>Démarré avec statut juridique</option>
                    </select>
                    <span class="icon"><i class="far fa-spinner"></i></span>
                    <div
                      *ngIf="
                        form.get('avancementProjet').errors &&
                        form.get('avancementProjet').touched
                      "
                      class="error"
                    >
                      <p *ngIf="form.get('avancementProjet').errors.required">
                        L'avancement du projet est obligatoire !
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="input-group mb-30">
                    <p class="col-lg-12 placeholder">Raison de ma demande</p>

                    <select formControlName="raisonDemande">
                      <option value="" disabled selected>Choisir</option>
                      <option>Être accompagné.e dans mon projet</option>
                      <option>Obtenir un aménagement de ma scolarité</option>
                      <option>
                        Valider des crédits de ma formation initiale
                      </option>
                      <option>
                        Prendre une année de césure pour la consacrer à mon
                        projet
                      </option>
                      <option>Valider une période de stage obligatoire</option>
                      <option>Me constituer un réseau</option>
                      <option>
                        Avoir accès à un espace de travail ou incubateur
                      </option>
                      <option>Autre</option>
                    </select>

                    <span class="icon"><i class="far fa-question"></i></span>
                    <div
                      *ngIf="
                        form.get('raisonDemande').errors &&
                        form.get('raisonDemande').touched
                      "
                      class="error"
                    >
                      <p *ngIf="form.get('raisonDemande').errors.required">
                        La raison de la demande est obligatoire !
                      </p>
                    </div>
                  </div>
                </div>
                <div class="separator col-lg-12 row"></div>
                <div class="col-lg-6">
                  <div class="input-group mb-30">
                    <p class="col-lg-12 placeholder">
                      Mon institution pour l'année en cours
                    </p>

                    <select formControlName="institution">
                      <option value="" disabled selected>Choisir</option>
                      <option
                        *ngFor="let pole of poles"
                        [ngValue]="pole.id"
                        [value]="pole.id"
                      >
                        {{ pole.name }}
                      </option>
                    </select>

                    <span class="icon"><i class="far fa-school"></i></span>
                    <div
                      *ngIf="
                        form.get('institution').errors &&
                        form.get('institution').touched
                      "
                      class="error"
                    >
                      <p *ngIf="form.get('institution').errors.required">
                        L'institut est obligatoire !
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="input-group mb-30">
                    <p class="col-lg-12 placeholder">
                      Mon établissement de rattachement pour l'année en cours
                    </p>
                    <textarea
                      placeholder="Mon établissement de rattachement pour l'année en cours (ou d'origine)"
                      formControlName="etablissementRattachement"
                    ></textarea>

                    <span class="icon"><i class="far fa-university"></i></span>
                    <div
                      *ngIf="
                        form.get('etablissementRattachement').errors &&
                        form.get('etablissementRattachement').touched
                      "
                      class="error"
                    >
                      <p
                        *ngIf="
                          form.get('etablissementRattachement').errors.required
                        "
                      >
                        L'établissement de rattachement est obligatoire !
                      </p>
                    </div>
                  </div>
                </div>
                <div class="separator col-lg-12 row"></div>
                <div class="col-lg-12 row">
                  <p class="col-lg-12 placeholder">
                    Le résponsable de ma formation d'origine
                  </p>
                  <div class="col-lg-6">
                    <div class="input-group mb-30">
                      <p class="col-lg-12 placeholder">
                        Le nom du résponsable de ma formation d'origine
                      </p>
                      <textarea
                        placeholder="Le nom du résponsable de formation"
                        formControlName="NomResponsableFormation"
                      ></textarea>

                      <span class="icon"
                        ><i class="far fa-chalkboard-teacher"></i
                      ></span>
                      <div
                        *ngIf="
                          form.get('NomResponsableFormation').errors &&
                          form.get('NomResponsableFormation').touched
                        "
                        class="error"
                      >
                        <p
                          *ngIf="
                            form.get('NomResponsableFormation').errors.required
                          "
                        >
                          Le nom du résponsable de formation est obligatoire !
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="input-group mb-30">
                      <p class="col-lg-12 placeholder">
                        Le prénom du résponsable de ma formation d'origine
                      </p>
                      <textarea
                        placeholder="Le prénom du résponsable de formation"
                        formControlName="PrenomResponsableFormation"
                      ></textarea>

                      <span class="icon"
                        ><i class="far fa-chalkboard-teacher"></i
                      ></span>
                      <div
                        *ngIf="
                          form.get('PrenomResponsableFormation').errors &&
                          form.get('PrenomResponsableFormation').touched
                        "
                        class="error"
                      >
                        <p
                          *ngIf="
                            form.get('PrenomResponsableFormation').errors
                              .required
                          "
                        >
                          Le prénom du résponsable de formation est obligatoire
                          !
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="input-group mb-30">
                      <p class="col-lg-12 placeholder">
                        La discipline du résponsable de ma formation
                      </p>
                      <textarea
                        placeholder="La discipline du résponsable de formation"
                        formControlName="DisciplineResponsableFormation"
                      ></textarea>

                      <span class="icon"
                        ><i class="far fa-users-class"></i
                      ></span>
                      <div
                        *ngIf="
                          form.get('DisciplineResponsableFormation').errors &&
                          form.get('DisciplineResponsableFormation').touched
                        "
                        class="error"
                      >
                        <p
                          *ngIf="
                            form.get('DisciplineResponsableFormation').errors
                              .required
                          "
                        >
                          La discipline du résponsable de formation est
                          obligatoire !
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="input-group mb-30">
                      <p class="col-lg-12 placeholder">
                        L'email du résponsable de ma formation d'origine
                      </p>
                      <textarea
                        placeholder="L'email du résponsable de formation"
                        formControlName="EmailResponsableFormation"
                      ></textarea>

                      <span class="icon"
                        ><i class="far fa-envelope-open-text"></i
                      ></span>
                      <div
                        *ngIf="
                          form.get('EmailResponsableFormation').errors &&
                          form.get('EmailResponsableFormation').touched
                        "
                        class="error"
                      >
                        <p
                          *ngIf="
                            form.get('EmailResponsableFormation').errors
                              .required
                          "
                        >
                          L'email du résponsable de formation est obligatoire !
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 row">
                  <p class="col-lg-12 placeholder">
                    La formation dans laquelle je suis inscrit pour l'année
                    universitaire en cours (ou dans ma dernière année)
                  </p>
                  <div class="col-lg-6">
                    <div class="input-group mb-30">
                      <p class="col-lg-12 placeholder">
                        Le type de la formation
                      </p>
                      <textarea
                        placeholder="Type de la formation"
                        formControlName="TypeformationInscription"
                      ></textarea>

                      <span class="icon"
                        ><i class="far fa-university"></i
                      ></span>
                      <div
                        *ngIf="
                          form.get('formationInscription').errors &&
                          form.get('formationInscription').touched
                        "
                        class="error"
                      >
                        <p
                          *ngIf="
                            form.get('formationInscription').errors.required
                          "
                        >
                          Le type de formation est obligatoire !
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="input-group mb-30">
                      <p class="col-lg-12 placeholder">
                        Libellé de la formation
                      </p>
                      <textarea
                        placeholder="Libellé de la formation"
                        formControlName="formationInscription"
                      ></textarea>

                      <span class="icon"
                        ><i class="far fa-university"></i
                      ></span>
                      <div
                        *ngIf="
                          form.get('formationInscription').errors &&
                          form.get('formationInscription').touched
                        "
                        class="error"
                      >
                        <p
                          *ngIf="
                            form.get('formationInscription').errors.required
                          "
                        >
                          Le libellé de la formation est obligatoire !
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <button type="submit" class="main-btn">Valider</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--====== Blog Section End ======-->

<!--====== Client Area Start ======-->

<!--====== Client Area End ======-->
<app-footer1></app-footer1>
