import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const API_URL = `${environment.apiUrl}`;

export class Api<Model> {
  constructor(private http: HttpClient, private path: string) {}

  setPath(path: string) {
    this.path = path;
  }

  private httpHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  list(parameters = {}): Observable<Model[]> {
    return this.http
      .get<Model[]>(`${API_URL}/${this.path}`, {
        headers: this.httpHeaders(),
        params: parameters,
      })
      .pipe(
        catchError((err) => {
          console.error('err', err);
          return of(undefined);
        })
      );
  }

  get(id: string): Observable<Model> {
    const response = this.http
      .get<any>(`${API_URL}/${this.path}/${id}`, {
        headers: this.httpHeaders(),
      })
      .pipe(
        catchError((err) => {
          console.error('err', err);
          return of(undefined);
        })
      );
    console.log(response);
    return response;
  }

  add(model: any): Observable<Model> {
    return this.http
      .post<Model>(`${API_URL}/${this.path}`, model, {
        headers: this.httpHeaders(),
      })
      .pipe(
        catchError((err) => {
          console.error('err', err);
          return of(undefined);
        })
      );
  }

  update(id: string, model: any): Observable<Model> {
    return this.http
      .patch<Model>(`${API_URL}/${this.path}/${id}`, model, {
        headers: this.httpHeaders(),
      })
      .pipe(
        catchError((err) => {
          console.error('err', err);
          return of(undefined);
        })
      );
  }

  del(id: string): Observable<any> {
    return this.http
      .delete<any>(`${API_URL}/${this.path}/${id}`, {
        headers: this.httpHeaders(),
      })
      .pipe(
        catchError((err) => {
          console.error('err', err);
          return of(undefined);
        })
      );
  }
}
