import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { PoleModel } from 'src/app/modules/_models/pole.model';
import { PersonModel } from 'src/app/modules/_models/person.model';
import { CompanyModel } from 'src/app/modules/_models/company.model';
import { Api } from 'src/app/modules/api';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.css'],
})
export class BlogDetailComponent implements OnInit {
  public form: FormGroup;
  public emailx: string;
  public poles: PoleModel[];
  public findInvalidControlsRecursive(
    formToInvestigate: FormGroup | FormArray
  ): string[] {
    var invalidControls: string[] = [];
    let recursiveFunc = (form: FormGroup | FormArray) => {
      Object.keys(form.controls).forEach((field) => {
        const control = form.get(field);
        if (control.invalid) invalidControls.push(field);
        if (control instanceof FormGroup) {
          recursiveFunc(control);
        } else if (control instanceof FormArray) {
          recursiveFunc(control);
        }
      });
    };
    recursiveFunc(formToInvestigate);
    return invalidControls;
  }
  equipe: FormArray;

  createItem(): FormGroup {
    return this.fb.group({
      nomCollaborateur: '',
      nomEtablissement: '',
    });
  }

  addItem(): void {
    this.equipe = this.form.get('equipe') as FormArray;
    this.equipe.push(this.createItem());
  }
  private poleApi: Api<PoleModel>;
  private personApi: Api<PersonModel>;
  constructor(
    private fb: FormBuilder,
    public httpClient: HttpClient,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params) => (this.emailx = params.email));

    this.poleApi = new Api(httpClient, PoleModel.path);
    this.personApi = new Api(httpClient, PersonModel.path);
  }

  ngOnInit() {
    this.getPoles();

    //while(this.poles == undefined);
    this.createForm();
    console.log('@@@@@@@@@@@@@@@@');
    console.log(this.route.snapshot.params.email);
    console.log(this.emailx);
    console.log(this.route);

    this.form.get('mail').setValue(this.route.snapshot.queryParams.email);
    this.form.get('lastname').setValue(this.route.snapshot.queryParams.nom);
  }

  createForm() {
    this.form = this.fb.group({
      lastname: ['', Validators.required],
      firstname: ['', Validators.required],
      birthdate: ['', Validators.required],
      password: ['', Validators.required],
      mail: [this.emailx, [Validators.required, Validators.email]],
      description: [''],
      telephone: ['', Validators.required],
      lieuNaissance: ['', Validators.required],
      genre: ['', Validators.required],
      cin: ['', Validators.required],
      cne: ['', Validators.required],
      handicap: ['', Validators.required],
      typeHandicap: [''],
      statutEtudiant: ['', Validators.required],
      typeEquipe: ['', Validators.required],
      equipe: this.fb.array([this.createItem()]),
      nomEtablissement: [''],
      typeProjet: ['', Validators.required],
      nomProjet: ['', Validators.required],
      resumeProjet: ['', Validators.required],
      vaProjet: ['', Validators.required],
      avancementProjet: ['', Validators.required],
      raisonDemande: ['', Validators.required],
      institution: ['', Validators.required],
      etablissementRattachement: ['', Validators.required],
      NomResponsableFormation: ['', Validators.required],
      PrenomResponsableFormation: ['', Validators.required],
      DisciplineResponsableFormation: ['', Validators.required],
      EmailResponsableFormation: ['', Validators.required],
      TypeformationInscription: ['', Validators.required],
      formationInscription: ['', Validators.required],
      paysNationalite: ['', Validators.required],
      role: ['CANDIDAT', Validators.required],
    });
  }
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
  submit() {
    this.form.markAllAsTouched();
    console.log(this.findInvalidControlsRecursive(this.form));
    if (this.form.valid) {
      const person = new PersonModel();
      person.firstname = this.form.get('firstname').value;
      person.lastname = this.form.get('lastname').value;
      person.email = this.form.get('mail').value;
      person.password = this.form.get('password').value;
      person.birthday = this.form.get('birthdate').value;
      person.role = 'CANDIDAT';
      person.poleId = this.form.get('institution').value;
      console.log(person.poleId);
      person.company = new CompanyModel();
      person.company.name = this.form.get('nomProjet').value;
      person.company.description = this.form.get('resumeProjet').value;
      person.data = JSON.stringify(this.form.value);
      console.log(person.data);
      this.personApi.add(person.data).subscribe(
        (response) => console.log(response),
        (error) => console.log(error)
      );
      window.location.href = 'https://saleem-maroc.org/connexion';
    } else {
      console.log('error');
      console.log(this.form.value);
      console.log(this.form.errors);
      this.markFormGroupTouched(this.form);
      alert('Attention, veuillez renseigner tous les champs');
    }
  }

  getPoles() {
    this.poleApi.list().subscribe((data: any) => {
      this.poles = data;
      console.log(data);
      console.log(this.poles);
    });
  }
}
