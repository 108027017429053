import { AuthModel } from "./auth.model";


export class AuditableModel extends AuthModel {
  static path: string;

  id: number;
  name: string;
  description: string;
  picture: string;

  setAuditable(auditable: any) {
    this.id = auditable.id;
    this.name = auditable.name || "";
    this.description = auditable.description || "";
    this.picture = auditable.picture || "./assets/media/users/default.jpg";
  }
}
