import { AuthModel } from './auth.model';
import { SocialNetworksModel } from './social-networks.model';
import { AuditableModel } from './auditable.model';
import { GrantModel } from './grant.model';
import { ParticipationModel } from './participation.model';
import { CompanyModel } from './company.model';
import { PoleModel } from './pole.model';

export class PersonModel extends AuditableModel {
  static path = '';

  email: string;
  password: string;
  role: string;
  firstname: string;
  lastname: string;
  poleId: PoleModel;
  phone: string;
  education: string;
  field: string;
  website: string;
  gender: string;
  disabilities: string[];
  birthday: Date;
  step: Number;
  grants: GrantModel[];
  company: CompanyModel;
  data: any;

  //occupation: string;
  //socialNetworks?: SocialNetworksModel;

  // account information
  // language: string;
  // timeZone: string;
  // communication: {
  //   email: boolean;
  //   sms: boolean;
  //   phone: boolean;
  // };
  // email settings
  // emailSettings: {
  //   emailNotification: boolean;
  //   sendCopyToPersonalEmail: boolean;
  //   activityRelatesEmail: {
  //     youHaveNewNotifications: boolean;
  //     youAreSentADirectMessage: boolean;
  //     someoneAddsYouAsAsAConnection: boolean;
  //     uponNewOrder: boolean;
  //     newMembershipApproval: boolean;
  //     memberRegistration: boolean;
  //   };
  //   updatesFromKeenthemes: {
  //     newsAboutKeenthemesProductsAndFeatureUpdates: boolean;
  //     tipsOnGettingMoreOutOfKeen: boolean;
  //     thingsYouMissedSindeYouLastLoggedIntoKeen: boolean;
  //     newsAboutMetronicOnPartnerProductsAndOtherServices: boolean;
  //     tipsOnMetronicBusinessProducts: boolean;
  //   };
  //};

  setPerson(person: any) {
    this.setAuditable(person);
    this.email = person.email;
    this.password = person.password;
    this.role = person.role;
    this.firstname = person.firstname;
    this.lastname = person.lastname;
    this.poleId = person.poleId || undefined;
    this.phone = person.phone || '';
    this.education = person.education || '';
    this.field = person.field || '';
    this.website = person.website || '';
    this.gender = person.gender || '';
    this.disabilities = person.disabilities || [];
    this.birthday = person.birthday || '';
    this.step = person.step || 0;
    this.grants = person.grants || [];
    this.company = person.company || undefined;
    this.data = person.data || {};
  }
}
