<select formControlName="paysNationalite">
  <option>Afghanistan</option>
  <option>Îles Åland</option>
  <option>Albanie</option>
  <option>Algérie</option>
  <option>Samoa américaines</option>
  <option>Andorre</option>
  <option>Angola</option>
  <option>Anguilla</option>
  <option>Antarctique</option>
  <option>Antigua-et-Barbuda</option>
  <option>Argentine</option>
  <option>Arménie</option>
  <option>Aruba</option>
  <option>Australie</option>
  <option>Autriche</option>
  <option>Azerbaïdjan</option>
  <option>Bahamas</option>
  <option>Bahreïn</option>
  <option>Bangladesh</option>
  <option>Barbade</option>
  <option>Biélorussie</option>
  <option>Belgique</option>
  <option>Belize</option>
  <option>Bénin</option>
  <option>Bermudes</option>
  <option>Bhoutan</option>
  <option>Bolivie</option>
  <option>Bosnie-Herzégovine</option>
  <option>Botswana</option>
  <option>Île Bouvet</option>
  <option>Brésil</option>
  <option>British Virgin Islands</option>
  <option>Territoire britannique de l’Océan Indien</option>
  <option>Brunei Darussalam</option>
  <option>Bulgarie</option>
  <option>Burkina Faso</option>
  <option>Burundi</option>
  <option>Cambodge</option>
  <option>Cameroun</option>
  <option>Canada</option>
  <option>Cap-Vert</option>
  <option>Iles Cayman</option>
  <option>République centrafricaine</option>
  <option>Tchad</option>
  <option>Chili</option>
  <option>Chine</option>
  <option>Hong Kong</option>
  <option>Macao</option>
  <option>Île Christmas</option>
  <option>Îles Cocos</option>
  <option>Colombie</option>
  <option>Comores</option>
  <option>République du Congo</option>
  <option>République démocratique du Congo</option>
  <option>Îles Cook</option>
  <option>Costa Rica</option>
  <option>Côte d’Ivoire</option>
  <option>Croatie</option>
  <option>Cuba</option>
  <option>Chypre</option>
  <option>République tchèque</option>
  <option>Danemark</option>
  <option>Djibouti</option>
  <option>Dominique</option>
  <option>République dominicaine</option>
  <option>Équateur</option>
  <option>Égypte</option>
  <option>Salvador</option>
  <option>Guinée équatoriale</option>
  <option>Érythrée</option>
  <option>Estonie</option>
  <option>Éthiopie</option>
  <option>Îles Falkland</option>
  <option>Îles Féroé</option>
  <option>Fidji</option>
  <option>Finlande</option>
  <option>France</option>
  <option>Guyane française</option>
  <option>Polynésie française</option>
  <option>Terres australes et antarctiques françaises</option>
  <option>Gabon</option>
  <option>Gambie</option>
  <option>Géorgie</option>
  <option>Allemagne</option>
  <option>Ghana</option>
  <option>Gibraltar</option>
  <option>Grèce</option>
  <option>Groenland</option>
  <option>Grenade</option>
  <option>Guadeloupe</option>
  <option>Guam</option>
  <option>Guatemala</option>
  <option>Guernesey</option>
  <option>Guinée</option>
  <option>Guinée-Bissau</option>
  <option>Guyane</option>
  <option>Haïti</option>
  <option>Îles Heard-et-MacDonald</option>
  <option>Saint-Siège (Vatican)</option>
  <option>Honduras</option>
  <option>Hongrie</option>
  <option>Islande</option>
  <option>Inde</option>
  <option>Indonésie</option>
  <option>Iran</option>
  <option>Irak</option>
  <option>Irlande</option>
  <option>Ile de Man</option>
  <option>Israël</option>
  <option>Italie</option>
  <option>Jamaïque</option>
  <option>Japon</option>
  <option>Jersey</option>
  <option>Jordanie</option>
  <option>Kazakhstan</option>
  <option>Kenya</option>
  <option>Kiribati</option>
  <option>Corée du Nord</option>
  <option>Corée du Sud</option>
  <option>Koweït</option>
  <option>Kirghizistan</option>
  <option>Laos</option>
  <option>Lettonie</option>
  <option>Liban</option>
  <option>Lesotho</option>
  <option>Libéria</option>
  <option>Libye</option>
  <option>Liechtenstein</option>
  <option>Lituanie</option>
  <option>Luxembourg</option>
  <option>Macédoine</option>
  <option>Madagascar</option>
  <option>Malawi</option>
  <option>Malaisie</option>
  <option>Maldives</option>
  <option>Mali</option>
  <option>Malte</option>
  <option>Îles Marshall</option>
  <option>Martinique</option>
  <option>Mauritanie</option>
  <option>Maurice</option>
  <option>Mayotte</option>
  <option>Mexique</option>
  <option>Micronésie</option>
  <option>Moldavie</option>
  <option>Monaco</option>
  <option>Mongolie</option>
  <option>Monténégro</option>
  <option>Montserrat</option>
  <option selected="selected">Maroc</option>
  <option>Mozambique</option>
  <option>Myanmar</option>
  <option>Namibie</option>
  <option>Nauru</option>
  <option>Népal</option>
  <option>Pays-Bas</option>
  <option>Nouvelle-Calédonie</option>
  <option>Nouvelle-Zélande</option>
  <option>Nicaragua</option>
  <option>Niger</option>
  <option>Nigeria</option>
  <option>Niue</option>
  <option>Île Norfolk</option>
  <option>Îles Mariannes du Nord</option>
  <option>Norvège</option>
  <option>Oman</option>
  <option>Pakistan</option>
  <option>Palau</option>
  <option>Palestine</option>
  <option>Panama</option>
  <option>Papouasie-Nouvelle-Guinée</option>
  <option>Paraguay</option>
  <option>Pérou</option>
  <option>Philippines</option>
  <option>Pitcairn</option>
  <option>Pologne</option>
  <option>Portugal</option>
  <option>Puerto Rico</option>
  <option>Qatar</option>
  <option>Réunion</option>
  <option>Roumanie</option>
  <option>Russie</option>
  <option>Rwanda</option>
  <option>Saint-Barthélemy</option>
  <option>Sainte-Hélène</option>
  <option>Saint-Kitts-et-Nevis</option>
  <option>Sainte-Lucie</option>
  <option>Saint-Martin (partie française)</option>
  <option>Saint-Martin (partie néerlandaise)</option>
  <option>Saint-Pierre-et-Miquelon</option>
  <option>Saint-Vincent-et-les Grenadines</option>
  <option>Samoa</option>
  <option>Saint-Marin</option>
  <option>Sao Tomé-et-Principe</option>
  <option>Arabie Saoudite</option>
  <option>Sénégal</option>
  <option>Serbie</option>
  <option>Seychelles</option>
  <option>Sierra Leone</option>
  <option>Singapour</option>
  <option>Slovaquie</option>
  <option>Slovénie</option>
  <option>Îles Salomon</option>
  <option>Somalie</option>
  <option>Afrique du Sud</option>
  <option>Géorgie du Sud et les îles Sandwich du Sud</option>
  <option>Sud-Soudan</option>
  <option>Espagne</option>
  <option>Sri Lanka</option>
  <option>Soudan</option>
  <option>Suriname</option>
  <option>Svalbard et Jan Mayen</option>
  <option>Eswatini</option>
  <option>Suède</option>
  <option>Suisse</option>
  <option>Syrie</option>
  <option>Taiwan</option>
  <option>Tadjikistan</option>
  <option>Tanzanie</option>
  <option>Thaïlande</option>
  <option>Timor-Leste</option>
  <option>Togo</option>
  <option>Tokelau</option>
  <option>Tonga</option>
  <option>Trinité-et-Tobago</option>
  <option>Tunisie</option>
  <option>Turquie</option>
  <option>Turkménistan</option>
  <option>Îles Turques-et-Caïques</option>
  <option>Tuvalu</option>
  <option>Ouganda</option>
  <option>Ukraine</option>
  <option>Émirats Arabes Unis</option>
  <option>Royaume-Uni</option>
  <option>États-Unis</option>
  <option>Îles mineures éloignées des États-Unis</option>
  <option>Uruguay</option>
  <option>Ouzbékistan</option>
  <option>Vanuatu</option>
  <option>Venezuela</option>
  <option>Viêt Nam</option>
  <option>Îles Vierges américaines</option>
  <option>Wallis-et-Futuna</option>
  <option>Sahara occidental</option>
  <option>Yémen</option>
  <option>Zambie</option>
  <option>Zimbabwe</option>
</select>
