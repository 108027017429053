<header class="sticky-header">
  <!-- Header Menu  -->
  <div class="header-nav">
    <div class="container-fluid container-1600">
      <div class="nav-container">
        <!-- Site Logo -->
        <div class="site-logo">
          <a routerLink="/">
            <img
              class="logo-regular"
              src="assets/img/logo2.png"
              style="height: 52px; margin-left: 40px"
              alt="Logo"
            /><img
              class="logo-regular"
              src="assets/img/logom.png"
              style="height: 61px; margin-left: 40px"
              alt="Logo"
            />
          </a>
          <a routerLink="/"
            ><img
              class="logo-sticky"
              src="assets/img/logo2.png"
              style="height: 61px; margin-left: 40px"
              alt="Logo"
          /></a>
        </div>

        <!-- Main Menu -->
        <div class="nav-menu d-lg-flex align-items-center">
          <!-- Navbar Close Icon -->
          <div class="navbar-close">
            <div class="cross-wrap"><span></span><span></span></div>
          </div>

          <!-- Mneu Items -->
          <div class="menu-items">
            <ul>
              <li class="">
                <a
                  target="_BLANK"
                  href="https://www.projet-saleem.org/le-projet/"
                  >LE PROJET</a
                >
              </li>
              <li class="">
                <a
                  target="_BLANK"
                  href="https://www.projet-saleem.org/resultats/"
                  >R&Eacute;SULTATS</a
                >
              </li>
              <li class="">
                <a
                  target="_BLANK"
                  href="https://www.projet-saleem.org/actualites/"
                  >ACTUALIT&Eacute;S</a
                >
              </li>

              <li>
                <a
                  target="_BLANK"
                  href="https://www.projet-saleem.org/revue-de-presse/"
                  >M&Eacute;DIA</a
                >
              </li>
              <li>
                <a
                  target="_BLANK"
                  href="https://www.projet-saleem.org/partenaires/"
                  >PARTENAIRES</a
                >
              </li>
            </ul>
          </div>
          <!-- Pushed Item -->
          <div class="nav-pushed-item"></div>
        </div>

        <!-- Navbar Extra  -->
        <div class="navbar-extra d-lg-block d-flex align-items-center">
          <!-- Navbtn -->
          <div class="navbar-btn nav-push-item">
            <a
              class="main-btn main-btn-3"
              href="https://saleem-maroc.org/connexion"
              >MON COMPTE</a
            >
          </div>

          <!-- Navbar Toggler -->
          <div class="navbar-toggler">
            <span></span><span></span><span></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
