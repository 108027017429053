<app-header1></app-header1>
<!--====== Banner part start ======-->
<section class="banner-section">
  <div class="banner-slider" id="bannerSlider">
    <div class="single-banner" style="background-color: #259da3">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="banner-content">
              <span
                class="promo-text"
                data-animation="fadeInDown"
                data-delay="0.8s"
              >
                Le statut national de l'étudiant entrepreneur
              </span>
              <h1 data-animation="fadeInUp" data-delay="1s">
                VOUS DONNER LES MOYENS D’ENTREPRENDRE
              </h1>
              <ul class="btn-wrap">
                <li data-animation="fadeInLeft" data-delay="1.2s">
                  <a
                    routerLink="/about-us"
                    class="main-btn main-btn-4"
                    style="background: #ffd227; color: black"
                    >Découvrir</a
                  >
                </li>
                <li data-animation="fadeInRight" data-delay="1.4s">
                  <a routerLink="/service-one" class="main-btn main-btn-2"
                    >Avantages</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-1"></div>
          <div class="col-lg-5">
            <form class="form-home" method="POST">
              <h3>Inscrivez-vous dès maintenant !</h3>
              <div class="input-group mb-10">
                <input name="name" id="nom" placeholder="Votre nom" />
              </div>
              <div class="input-group mb-10">
                <input
                  name="email"
                  id="email"
                  type="email"
                  placeholder="Votre email"
                />
              </div>
              <div class="input-group mb-10">
                <button (click)="redirect()">Je m'inscris !!</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="search-wrap">
    <a routerLink="/" class="search-icon"><i class="far fa-search"></i></a>
  </div>
</section>
<!--====== Banner part end ======-->

<!--====== About Section start ======-->
<section class="about-section about-illustration-img section-gap">
  <div class="container">
    <div class="illustration-img">
      <img src="assets/img/illustration/01.png" alt="Image" />
    </div>
    <div class="row no-gutters justify-content-lg-end justify-content-center">
      <div class="col-lg-6 col-md-10">
        <div class="about-text">
          <div class="section-title left-border mb-40">
            <span class="title-tag">SALEEM</span>
            <h2 class="title">Présentation</h2>
          </div>
          <p>
            Dans le cadre de la consolidation des initiatives entreprises depuis
            quelques ann&eacute;es en mati&egrave;re de d&eacute;veloppement de
            l&rsquo;esprit entrepreneurial et d&rsquo;auto-emploi chez les
            &eacute;tudiants, le Minist&egrave;re de l&rsquo;Education
            Nationale, de la Formation Professionnelle, de l&rsquo;Enseignement
            Sup&eacute;rieur et de la Recherche Scientifique a mis en place, en
            vertu de la Circulaire Minist&eacute;rielle 01/01488 du 20
            d&eacute;cembre 2018, le &nbsp;Statut National de l&rsquo;Etudiant
            Entrepreneur (SNEE) et les P&ocirc;les de Services
            d&rsquo;Accompagnement &agrave; l&rsquo;Entrepreneuriat Etudiant au
            Maroc (SALEEM) pour l&rsquo;accompagnement des projets
            entrepreneuriaux des &eacute;tudiants en partenariat avec les
            acteurs &eacute;conomiques. Le SNEE constitue un outil facilitateur
            pour l&rsquo;&eacute;tudiant afin de tisser des relations avec les
            diff&eacute;rents acteurs dans le monde de l&rsquo;entreprise
            (banquiers, fournisseurs, clients, etc.) tout en int&eacute;grant un
            projet entrepreneurial dans le parcours de formation.
          </p>
          <p>
            Ce statut s&rsquo;adresse aux &eacute;tudiants inscrits en
            derni&egrave;re ann&eacute;e de formation dans un dipl&ocirc;me
            d&eacute;livr&eacute; par un &eacute;tablissement
            d&rsquo;enseignement sup&eacute;rieur public ou un
            &eacute;tablissement d&rsquo;enseignement sup&eacute;rieur
            priv&eacute; reconnu par l&rsquo;Etat.
          </p>
          <p>
            Les &eacute;tudiants int&eacute;ress&eacute;s peuvent postuler au
            SNEE, en ligne, en remplissant le formulaire disponible dans la
            rubrique &laquo; D&eacute;poser votre candidature &raquo; sur ce
            site.
          </p>
          <p>
            Sur d&eacute;l&eacute;gation du minist&egrave;re, le SNEE est
            d&eacute;livr&eacute; &agrave; l&rsquo;&eacute;tudiant par le
            Pr&eacute;sident de l&rsquo;Universit&eacute; au vu de
            l&rsquo;instruction r&eacute;alis&eacute;e par le P&ocirc;le SALEEM
            de l&rsquo;universit&eacute; &agrave; laquelle son
            &eacute;tablissement est rattach&eacute;.
          </p>
          <p>
            Dans une perspective de g&eacute;n&eacute;ralisation du SNEE au
            niveau de toutes les universit&eacute;s nationales, &agrave; partir
            de la rentr&eacute;e universitaire 2021-2022, une exp&eacute;rience
            pilote a &eacute;t&eacute; men&eacute;e, depuis l&rsquo;ann&eacute;e
            universitaire 2018-2019, dans les villes de Casablanca et Rabat dont
            les deux universit&eacute;s respectives (Hassan II et Mohamed V) ont
            d&eacute;j&agrave; mis en place des P&ocirc;les
            d&rsquo;Accompagnement de l&rsquo;Entrepreneuriat &Eacute;tudiant au
            Maroc dans le cadre du projet ERASMUS+ SALEEM, co-financ&eacute; par
            l&rsquo;Union Europ&eacute;enne et coordonn&eacute; par
            l&rsquo;Agence Universitaire de la Francophonie (AUF)&nbsp;
          </p>
          <p><br /></p>
          <style>
            .tb_button {
              padding: 1px;
              cursor: pointer;
              border-right: 1px solid #8b8b8b;
              border-left: 1px solid #fff;
              border-bottom: 1px solid #fff;
            }

            .tb_button.hover {
              borer: 2px outset #def;
              background-color: #f8f8f8 !important;
            }

            .ws_toolbar {
              z-index: 100000;
            }

            .ws_toolbar .ws_tb_btn {
              cursor: pointer;
              border: 1px solid #555;
              padding: 3px;
            }

            .tb_highlight {
              background-color: yellow;
            }

            .tb_hide {
              visibility: hidden;
            }

            .ws_toolbar img {
              padding: 2px;
              margin: 0px;
            }
          </style>

          <a routerLink="/about-us" class="main-btn">En savoir plus</a>
        </div>
      </div>
    </div>
  </div>
</section>
<!--====== About Section end ======-->

<!--====== Service Part Start ======-->
<section class="service-section shape-style-one section-gap grey-bg">
  <div class="container">
    <!-- Section Title -->
    <div class="section-title text-center both-border mb-30">
      <span class="title-tag">Obtenir le statut</span>
      <h2 class="title">Comment ça marche</h2>
    </div>
    <!-- Services Boxes -->
    <div class="row service-boxes justify-content-center">
      <div
        class="col-lg-4 col-md-6 col-sm-8 col-10 col-tiny-12 wow fadeInLeft"
        data-wow-duration="1500ms"
        data-wow-delay="400ms"
      >
        <div class="service-box text-center">
          <div class="icon">
            <img src="assets/img/icons/01.png" alt="Icon" />
          </div>
          <h3><a routerLink="/service-detail">Remplissez le formulaire</a></h3>
          <p>Sed ut perspiciatis unde omnis iste natus erro volupta</p>
          <a routerLink="/service-detail" class="service-link">
            <i class="fal fa-long-arrow-right"></i>
          </a>
        </div>
      </div>
      <div
        class="col-lg-4 col-md-6 col-sm-8 col-10 col-tiny-12 wow fadeInUp"
        data-wow-duration="1500ms"
        data-wow-delay="600ms"
      >
        <div class="service-box text-center">
          <div class="icon">
            <img src="assets/img/icons/02.png" alt="Icon" />
          </div>
          <h3><a routerLink="/service-detail">Recevez votre convocation</a></h3>
          <p>Sed ut perspiciatis unde omnis iste natus erro volupta</p>
          <a routerLink="/service-detail" class="service-link">
            <i class="fal fa-long-arrow-right"></i>
          </a>
        </div>
      </div>
      <div
        class="col-lg-4 col-md-6 col-sm-8 col-10 col-tiny-12 wow fadeInRight"
        data-wow-duration="1500ms"
        data-wow-delay="800ms"
      >
        <div class="service-box text-center">
          <div class="icon">
            <img src="assets/img/icons/03.png" alt="Icon" />
          </div>
          <h3>
            <a routerLink="/service-detail">Passez l'entretien de sélection</a>
          </h3>
          <p>Sed ut perspiciatis unde omnis iste natus erro volupta</p>
          <a routerLink="/service-detail" class="service-link">
            <i class="fal fa-long-arrow-right"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="dots-line">
    <img src="assets/img/lines/07.png" alt="Image" />
  </div>
</section>
<!--====== Service Part End ======-->

<!--====== Video Start ======-->
<section
  class="video-section bg-img-c section-gap"
  style="background-image: url(assets/img/video-bg/video-1.jpg)"
>
  <div class="container">
    <div class="row align-items-center justify-content-between">
      <div class="col-xl-7 col-lg-8 col-md-10 order-2 order-md-1">
        <div class="video-text">
          <div class="section-title left-border mb-30">
            <span class="title-tag">Capsule vidéo</span>
            <h2 class="title">Découvrez nos pépites</h2>
          </div>
          <p>
            Ranim ad minima veniam, quis nostrum exercitationem ullam
            corpsuscipit laboriosam, nisi ut aliquid exea commodi consequa
            turuis autem
          </p>
          <a routerLink="/" class="main-btn">Nos startups</a>
        </div>
      </div>
      <div class="col-lg-3 col-lg-4 col-md-2 order-1 order-md-2">
        <div
          class="video-btn text-md-center wow fadeInUp"
          data-wow-duration="1500ms"
          data-wow-delay="400ms"
        >
          <a routerLink="/" class="play-btn popup-video">
            <img src="assets/img/icons/play.svg" alt="" />
            <i class="fas fa-play"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="line-shape">
    <img src="assets/img/lines/08.png" alt="Line" />
  </div>
</section>
<!--====== Video end ======-->

<!--====== Feature Part start ======-->
<section class="feature-section section-gap">
  <div class="container">
    <div class="section-title text-center both-border mb-50">
      <span class="title-tag">
        Nous vous présentons les jeunes entrepreneurs issus du programme
      </span>
      <h2 class="title">La communauté SNEE</h2>
    </div>
    <!-- Feature boxes -->
    <div class="feature-boxes row justify-content-center">
      <div class="col-lg-4 col-md-6 col-10 col-tiny-12">
        <div class="feature-box">
          <div
            class="feature-bg bg-img-c"
            style="background-image: url(assets/img/feature/01.jpg)"
          ></div>
          <div class="feature-desc">
            <a routerLink="/portfolio-detail" class="feature-link"
              ><i class="fal fa-long-arrow-right"></i
            ></a>
            <h4>Mouad Mkamel</h4>
            <p>VENINA</p>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-10 col-tiny-12">
        <div class="feature-box">
          <div
            class="feature-bg bg-img-c"
            style="background-image: url(assets/img/feature/03.jpg)"
          ></div>
          <div class="feature-desc">
            <a routerLink="/portfolio-detail" class="feature-link"
              ><i class="fal fa-long-arrow-right"></i
            ></a>
            <h4>Imane Naoumi, Hasnaa El Mandili</h4>
            <p>COSMALGUE</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-10 col-tiny-12">
        <div class="feature-box">
          <div
            class="feature-bg bg-img-c"
            style="background-image: url(assets/img/feature/04.jpg)"
          ></div>
          <div class="feature-desc">
            <a routerLink="/portfolio-detail" class="feature-link"
              ><i class="fal fa-long-arrow-right"></i
            ></a>
            <h4>Hamza Echarrafi</h4>
            <p>SGMG</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-10 col-tiny-12">
        <div class="feature-box">
          <div
            class="feature-bg bg-img-c"
            style="background-image: url(assets/img/feature/02.jpg)"
          ></div>
          <div class="feature-desc">
            <a routerLink="/portfolio-detail" class="feature-link"
              ><i class="fal fa-long-arrow-right"></i
            ></a>
            <h4>Meryeme Lafghir</h4>
            <p>BIOA.MENT</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-10 col-tiny-12">
        <div class="feature-box">
          <div
            class="feature-bg bg-img-c"
            style="background-image: url(assets/img/feature/05.jpg)"
          ></div>
          <div class="feature-desc">
            <a routerLink="/portfolio-detail" class="feature-link"
              ><i class="fal fa-long-arrow-right"></i
            ></a>
            <h4>Halima ESSAGHIR</h4>
            <p>VÉGÉCUTERIE</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-10 col-tiny-12">
        <div class="feature-box">
          <div
            class="feature-bg bg-img-c"
            style="background-image: url(assets/img/feature/06.jpg)"
          ></div>
          <div class="feature-desc">
            <a routerLink="/portfolio-detail" class="feature-link"
              ><i class="fal fa-long-arrow-right"></i
            ></a>
            <h4>Relationship</h4>
            <p>Join us for consultatoins</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--====== Feature Part end ======-->

<!--====== Why Choose Us Part Start ======-->
<section class="wcu-section box-style">
  <div class="container">
    <div class="wcu-inner">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-6">
          <div
            class="wcu-image text-center text-lg-left wow fadeInUp"
            data-wow-duration="1500ms"
            data-wow-delay="400ms"
          >
            <img src="assets/img/illustration/02.png" alt="Image" />
          </div>
        </div>
        <div class="col-lg-6 col-md-10">
          <div class="wcu-text">
            <div class="section-title left-border mb-40">
              <span class="title-tag">Nous trouver</span>
              <h2 class="title">Nos pôles d’accompagnement au Maroc</h2>
            </div>
            <p>
              Inspiré par l’initiative PEPITE France, les pôles d’accompagnement
              permettent aux étudiant-e-s et aux jeunes diplômé-e-s de se former
              à l’entrepreneuriat et à l’innovation puis de concrétiser leurs
              projets de création d’entreprise.
            </p>
            <ul class="wcu-list">
              <li>
                <i class="far fa-check-circle"></i> UH2C - Pôle Etudiant
                Entrepreneur SALEEM Casablanca - UH2C
              </li>
              <li>
                <i class="far fa-check-circle"></i>UM5R - Pôle Etudiant
                Entrepreneur SALEEM Rabat
              </li>
            </ul>

            <a routerLink="/contact-us" class="main-btn main-btn-4"
              >Contactez-nous</a
            >
          </div>
        </div>
      </div>
      <img src="assets/img/lines/03.png" alt="shape" class="line-shape-one" />
      <img src="assets/img/lines/04.png" alt="shape" class="line-shape-two" />
    </div>
  </div>
</section>
<!--====== Why Choose Us Part End ======-->

<!--====== Fact Part Start ======-->
<section class="fact-section grey-bg">
  <div class="container">
    <div class="fact-boxes row justify-content-between align-items-center">
      <div class="col-lg-3 col-6">
        <div class="fact-box text-center mb-40">
          <div class="icon">
            <i class="flaticon-graphic"></i>
          </div>
          <h2 class="counter">4</h2>
          <p class="title">ans de projet</p>
        </div>
      </div>
      <div class="col-lg-3 col-6">
        <div class="fact-box text-center mb-40">
          <div class="icon">
            <i class="flaticon-plan"></i>
          </div>
          <h2 class="counter">14</h2>
          <p class="title">partenaires</p>
        </div>
      </div>
      <div class="col-lg-3 col-6">
        <div class="fact-box text-center mb-40">
          <div class="icon">
            <i class="flaticon-target-1"></i>
          </div>
          <h2 class="counter">2400</h2>
          <p class="title">étudiant accompagnés</p>
        </div>
      </div>
      <div class="col-lg-3 col-6">
        <div class="fact-box text-center mb-40">
          <div class="icon">
            <i class="flaticon-teamwork"></i>
          </div>
          <h2 class="counter">65%</h2>
          <p class="title">de projets réussis</p>
        </div>
      </div>
    </div>
  </div>
</section>
<!--====== Fact Part End ======-->

<!--====== Team Section Start ======-->
<section class="team-section section-gap">
  <div class="container">
    <!-- Section Title -->
    <div class="section-title mb-40 both-border text-center">
      <span class="title-tag">Le statut national d'étudiant entrepreneur</span>
      <h2 class="title">Zoom sur le projet</h2>
    </div>

    <!-- Team Boxes -->
    <div class="row team-members" id="teamSliderOne">
      <div class="col-lg-3">
        <div class="team-member">
          <div class="member-picture-wrap">
            <div class="member-picture">
              <img src="assets/img/team/01.jpg" alt="TeamMember" />
              <div class="social-icons">
                <a href="https://www.projet-saleem.org/">En savoir plus</a>
              </div>
            </div>
          </div>
          <div class="member-desc">
            <h3 class="name">
              <a href="https://www.projet-saleem.org/"> Pourquoi</a>
            </h3>
            <span class="pro">le projet SALEEM ? </span>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="team-member">
          <div class="member-picture-wrap">
            <div class="member-picture">
              <img src="assets/img/team/02.jpg" alt="TeamMember" />
              <div class="social-icons">
                <a>En savoir plus</a>
              </div>
            </div>
          </div>
          <div class="member-desc">
            <h3 class="name">
              <a href="https://www.projet-saleem.org/">Les bénéficiaires</a>
            </h3>
            <span class="pro">du projet</span>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="team-member">
          <div class="member-picture-wrap">
            <div class="member-picture">
              <img src="assets/img/team/03.jpg" alt="TeamMember" />
              <div class="social-icons">
                <a href="https://www.projet-saleem.org/">En savoir plus</a>
              </div>
            </div>
          </div>
          <div class="member-desc">
            <h3 class="name">
              <a href="https://www.projet-saleem.org/">Activités</a>
            </h3>
            <span class="pro">principales du projet</span>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="team-member">
          <div class="member-picture-wrap">
            <div class="member-picture">
              <img src="assets/img/team/04.jpg" alt="TeamMember" />
              <div class="social-icons">
                <a href="https://www.projet-saleem.org/">En savoir plus</a>
              </div>
            </div>
          </div>
          <div class="member-desc">
            <h3 class="name">
              <a href="https://www.projet-saleem.org/">Initiatives</a>
            </h3>
            <span class="pro">du projet SALEEM</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--====== Team Section End ======-->

<!--====== Skill Section Start ======-->
<section class="skill-section">
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-lg-6 col-md-10">
        <!-- Skill Text Block -->
        <div class="skill-text">
          <div class="section-title mb-40 left-border">
            <span class="title-tag">Les meilleurs de nos experts</span>
            <h2 class="title">pour vous accompagner à lancer votre startup</h2>
          </div>
          <p>
            Le SNEE permet aux étudiants d’être reconnus pour le travail réalisé
            pour élaborer et mettre en œuvre un projet entrepreneurial. Ils sont
            pour cela accompagnés au sein d’un Pôle SALEEM. Ce Statut permet aux
            jeunes de bénéficier d’aménagements de scolarité et de services
            d’accompagnement pour leur permettre de mener à bien leur projet
            avec un maximum de sécurité et de visibilité.
          </p>

          <a href="https://www.projet-saleem.org/" class="main-btn"
            >En savoir plus</a
          >
        </div>
      </div>
      <div class="col-lg-6 col-md-10">
        <div class="piechart-boxes">
          <div class="chart-box">
            <div class="chart" data-percent="25">
              <i class="flaticon-presentation"></i>
            </div>
            <h4 class="title">Business Model</h4>
          </div>
          <div class="chart-box">
            <div class="chart" data-percent="40">
              <i class="flaticon-money-bags"></i>
            </div>
            <h4 class="title">Étude financière</h4>
          </div>
          <div class="chart-box">
            <div class="chart" data-percent="75">
              <i class="flaticon-invest"></i>
            </div>
            <h4 class="title">Étude de marché approfondi</h4>
          </div>
          <div class="chart-box">
            <div class="chart" data-percent="80">
              <i class="flaticon-connector"></i>
            </div>
            <h4 class="title">Démarche administrative</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--====== Skill Section End ======-->

<!--====== Testimonials part start ======-->

<!--====== Testimonials part end ======-->

<!--====== Client Area Start ======-->
<section class="client-section">
  <div class="container">
    <div class="client-slider section-gap line-bottom">
      <div
        class="row align-items-center justify-content-between"
        id="clientSlider"
      >
        <div class="col">
          <a
            href="https://www.projet-saleem.org/"
            class="client-img d-block text-center"
          >
            <img src="assets/img/clients/01.png" alt="" />
          </a>
        </div>
        <div class="col">
          <a
            href="https://www.projet-saleem.org/"
            class="client-img d-block text-center"
          >
            <img src="assets/img/clients/02.png" alt="" />
          </a>
        </div>
        <div class="col">
          <a
            href="https://www.projet-saleem.org/"
            class="client-img d-block text-center"
          >
            <img src="assets/img/clients/03.png" alt="" />
          </a>
        </div>
        <div class="col">
          <a
            href="https://www.projet-saleem.org/"
            class="client-img d-block text-center"
          >
            <img src="assets/img/clients/04.png" alt="" />
          </a>
        </div>
        <div class="col">
          <a
            href="https://www.projet-saleem.org/"
            class="client-img d-block text-center"
          >
            <img src="assets/img/clients/05.png" alt="" />
          </a>
        </div>
        <div class="col">
          <a
            href="https://www.projet-saleem.org/"
            class="client-img d-block text-center"
          >
            <img src="assets/img/clients/01.png" alt="" />
          </a>
        </div>
        <div class="col">
          <a
            href="https://www.projet-saleem.org/"
            class="client-img d-block text-center"
          >
            <img src="assets/img/clients/02.png" alt="" />
          </a>
        </div>
        <div class="col">
          <a
            href="https://www.projet-saleem.org/"
            class="client-img d-block text-center"
          >
            <img src="assets/img/clients/03.png" alt="" />
          </a>
        </div>
        <div class="col">
          <a
            href="https://www.projet-saleem.org/"
            class="client-img d-block text-center"
          >
            <img src="assets/img/clients/04.png" alt="" />
          </a>
        </div>
        <div class="col">
          <a
            href="https://www.projet-saleem.org/"
            class="client-img d-block text-center"
          >
            <img src="assets/img/clients/05.png" alt="" />
          </a>
        </div>
        <div class="col">
          <a
            href="https://www.projet-saleem.org/"
            class="client-img d-block text-center"
          >
            <img src="assets/img/clients/06.png" alt="" />
          </a>
        </div>
        <div class="col">
          <a
            href="https://www.projet-saleem.org/"
            class="client-img d-block text-center"
          >
            <img src="assets/img/clients/07.png" alt="" />
          </a>
        </div>
        <div class="col">
          <a
            href="https://www.projet-saleem.org/"
            class="client-img d-block text-center"
          >
            <img src="assets/img/clients/08.png" alt="" />
          </a>
        </div>
        <div class="col">
          <a
            href="https://www.projet-saleem.org/"
            class="client-img d-block text-center"
          >
            <img src="assets/img/clients/09.png" alt="" />
          </a>
        </div>
        <div class="col">
          <a routerLink="/" class="client-img d-block text-center">
            <img src="assets/img/clients/10.png" alt="" />
          </a>
        </div>
        <div class="col">
          <a routerLink="/" class="client-img d-block text-center">
            <img src="assets/img/clients/11.png" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<!--====== Client Area End ======-->

<!--====== Latest Post Start ======-->
<section class="latest-post-section section-gap">
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-lg-6 col-md-8 col-10 col-tiny-12">
        <div class="section-title left-border">
          <span class="title-tag">Le Blog</span>
          <h2 class="title">Suivez notre actualité</h2>
        </div>
      </div>
      <div class="col-lg-6 col-md-4 col-10 col-tiny-12">
        <div class="text-md-right mt-30 mt-md-0">
          <a href="https://www.projet-saleem.org/" class="main-btn"
            >Voir tout</a
          >
        </div>
      </div>
    </div>
    <div class="latest-post-loop row mt-50 justify-content-center">
      <div
        class="col-lg-4 col-md-6 col-10 col-tiny-12 wow fadeInLeft"
        data-wow-duration="1500ms"
        data-wow-delay="400ms"
      >
        <div class="latest-post-box">
          <div class="post-thumb-wrap">
            <div
              class="post-thumb bg-img-c"
              style="
                background-image: url(https://www.projet-saleem.org/wp-content/uploads/2021/03/TWT_AUF_Saleem_FINAL.jpg);
              "
            ></div>
          </div>
          <div class="post-desc">
            <span class="post-date"
              ><i class="far fa-calendar-alt"></i>25 JAN 2021</span
            >
            <h3 class="title">
              <a href="https://www.projet-saleem.org/">
                Rencontre internationale Saleem : Comment promouvoir la culture
                entrepreneuriale à l’université ?
              </a>
            </h3>
            <p>
              Rencontre internationale de bilan et de capitalisation de SALEEM
              du 30 mars au 2 avril 2021. Un événement 100% digital, gratuit,
              ouvert...
            </p>
            <a href="https://www.projet-saleem.org/" class="post-link">
              Lire l'article <i class="far fa-long-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
      <div
        class="col-lg-4 col-md-6 col-10 col-tiny-12 wow fadeInUp"
        data-wow-duration="1500ms"
        data-wow-delay="600ms"
      >
        <div class="latest-post-box">
          <div class="post-thumb-wrap">
            <div
              class="post-thumb bg-img-c"
              style="
                background-image: url(https://www.projet-saleem.org/wp-content/uploads/2020/06/Saleem-ChallengeVivo.jpg);
              "
            ></div>
          </div>
          <div class="post-desc">
            <span class="post-date"
              ><i class="far fa-calendar-alt"></i>10 JAN 2020</span
            >
            <h3 class="title">
              <a href="https://www.projet-saleem.org/">
                Un Innovation Camp virtuel pour 185 étudiant.e.s de l’Université
                Hassan II de Casablanca
              </a>
            </h3>
            <p>
              Un Innovation Camp virtuel pour 185 étudiant.e.s de l’Université
              Hassan II de Casablanca
            </p>
            <a href="https://www.projet-saleem.org/" class="post-link">
              Lire l'article <i class="far fa-long-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
      <div
        class="col-lg-4 col-md-6 col-10 col-tiny-12 wow fadeInRight"
        data-wow-duration="1500ms"
        data-wow-delay="800ms"
      >
        <div class="latest-post-box">
          <div class="post-thumb-wrap">
            <div
              class="post-thumb bg-img-c"
              style="
                background-image: url(https://www.projet-saleem.org/wp-content/uploads/2020/07/Capture-d%E2%80%99%C3%A9cran-2020-07-24-%C3%A0-12.38.48.png);
              "
            ></div>
          </div>
          <div class="post-desc">
            <span class="post-date"
              ><i class="far fa-calendar-alt"></i>18 JAN 2021</span
            >
            <h3 class="title">
              <a href="https://www.projet-saleem.org/">
                Cérémonie de remise des attestations du statut national de
                l’Etudiant-Entrepreneur à Rabat
              </a>
            </h3>
            <p>
              Le Pôle Saleem de Rabat a organisé le mardi 21 juillet 2020 une
              cérémonie de remise des attestations du statut national...
            </p>
            <a href="https://www.projet-saleem.org/" class="post-link">
              Lire l'article <i class="far fa-long-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--====== Latest Post Start ======-->
<app-footer1></app-footer1>
