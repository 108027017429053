import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-homepage1',
  templateUrl: './homepage1.component.html',
  styleUrls: ['./homepage1.component.css'],
})
export class Homepage1Component implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {}

  redirect() {
    var mail = (<HTMLInputElement>document.getElementById('email')).value;
    var nom = (<HTMLInputElement>document.getElementById('nom')).value;
    this.router.navigate(['/form'], {
      queryParams: { email: mail, nom: nom },
    });
  }
}
