<footer>
  <div class="container">
    <div class="footer-widget">
      <div class="row">
        <div class="col-lg-4 col-sm-5 order-1">
          <div class="widget site-info-widget">
            <div class="footer-logo">
              <img src="assets/img/logo2.png" alt="SNEE" />
            </div>
            <p>LE STATUT NATIONAL DE L'ETUDIANT ENTREPRENEUR</p>
            <ul class="social-links">
              <li>
                <a href="javascript:void(0)"
                  ><i class="fab fa-facebook-f"></i
                ></a>
              </li>
              <li>
                <a href="javascript:void(0)"><i class="fab fa-twitter"></i></a>
              </li>
              <li>
                <a href="javascript:void(0)"
                  ><i class="fab fa-instagram"></i
                ></a>
              </li>
              <li>
                <a href="javascript:void(0)"><i class="fab fa-behance"></i></a>
              </li>
              <li>
                <a href="javascript:void(0)"><i class="fab fa-dribbble"></i></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-8 col-sm-7 order-2">
          <div class="widget newsletter-widget">
            <h4 class="widget-title">Nous suivre - Newsletter</h4>
            <div class="newsletter-form">
              <form action="#">
                <input type="email" placeholder="Saisissez votre email" />
                <button type="button" class="main-btn">Je m'inscris</button>
              </form>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 order-3">
          <div class="widget nav-widget">
            <h4 class="widget-title">Accès rapide</h4>
            <ul>
              <li><a href="javascript:void(0)">LE PROJET</a></li>
              <li><a href="javascript:void(0)">AVANTAGES</a></li>
              <li><a href="javascript:void(0)">NOS ACTIONS</a></li>
              <li><a href="javascript:void(0)">ACTUALITÉS</a></li>
              <li><a href="javascript:void(0)">MÉDIA</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-5 order-lg-4 order-5">
          <div class="row">
            <div class="col-lg-6 col-sm-6">
              <div class="widget nav-widget">
                <h4 class="widget-title">Partenaires</h4>
                <ul>
                  <li><a href="javascript:void(0)">Maroc</a></li>
                  <li><a href="javascript:void(0)">Europe</a></li>
                </ul>
              </div>
            </div>
            <div class="col-lg-6 col-sm-6">
              <div class="widget contact-widget">
                <h4 class="widget-title">Contactez-nous</h4>
                <p>Besoin d'aide ? N'hésitez pas à nous contacter</p>
                <ul class="contact-infos">
                  <li>
                    <a href="tel:+0123456789">
                      <i class="far fa-phone"></i>
                      +0212 6 67 77 88 99</a
                    >
                  </li>
                  <li>
                    <a href="mailto:support@gmail.com">
                      <i class="far fa-envelope-open"></i>
                      support@snee.ma
                    </a>
                  </li>
                  <li>
                    <i class="far fa-map-marker-alt"></i> UH2C - 19, Rue Tarik
                    Ibnou Ziad, Mers Sultan Casablanca <br /><i
                      class="far fa-map-marker-alt"
                    ></i>
                    UM5 - Avenue des Nations Unies, Agdal
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 order-lg-5 order-4">
          <div class="widget insta-feed-widget">
            <h4 class="widget-title">Galerie</h4>
            <div class="insta-images">
              <div
                class="insta-img"
                style="background-image: url(assets/img/instagram/01.jpg)"
              >
                <a href="javascript:void(0)">
                  <i class="fab fa-instagram"></i>
                </a>
              </div>
              <div
                class="insta-img"
                style="background-image: url(assets/img/instagram/02.jpg)"
              >
                <a href="javascript:void(0)">
                  <i class="fab fa-instagram"></i>
                </a>
              </div>
              <div
                class="insta-img"
                style="background-image: url(assets/img/instagram/03.jpg)"
              >
                <a href="javascript:void(0)">
                  <i class="fab fa-instagram"></i>
                </a>
              </div>
              <div
                class="insta-img"
                style="background-image: url(assets/img/instagram/04.jpg)"
              >
                <a href="javascript:void(0)">
                  <i class="fab fa-instagram"></i>
                </a>
              </div>
              <div
                class="insta-img"
                style="background-image: url(assets/img/instagram/05.jpg)"
              >
                <a href="javascript:void(0)">
                  <i class="fab fa-instagram"></i>
                </a>
              </div>
              <div
                class="insta-img"
                style="background-image: url(assets/img/instagram/06.jpg)"
              >
                <a href="javascript:void(0)">
                  <i class="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-copyright">
      <p class="copyright-text">
        <span>Copy@ <a href="javascript:void(0)">SNEE</a>.-2021</span>
        <span>Mentions légales</span>
      </p>

      <a href="javascript:void(0)" class="back-to-top"
        ><i class="far fa-angle-up"></i
      ></a>
    </div>
  </div>

  <!-- Lines -->
  <img src="assets/img/lines/01.png" alt="line-shape" class="line-one" />
  <img src="assets/img/lines/02.png" alt="line-shape" class="line-two" />
</footer>
