import { PersonModel } from './person.model';
import { ParticipationModel } from './participation.model';
import { AuditableModel } from './auditable.model';

export class PoleModel extends AuditableModel {
  static path = '';

  setPole(pole: any) {
    this.setAuditable(pole);
  }
}
