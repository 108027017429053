import { PersonModel } from "./person.model";
import { ParticipationModel } from "./participation.model";
import { AuditableModel } from "./auditable.model";

export class CompanyModel extends AuditableModel {
  static path = "companies";

  address: string;
  zipcode: string;
  city: string;

  setCompany(company: any) {
    this.setAuditable(company);
    this.address = company.address || "";
    this.zipcode = company.zipcode || "";
    this.city = company.city || "";
  }

  
}
